@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Permanent+Marker&family=Unica+One&display=swap');

$font-family-base: 'PT Sans', sans-serif;
$font-size-base: 0.95rem;
$primary: #003F03;
$secondary: #f8f5f0;
$orange: #CC8939;
$link-color: $primary;
$link-hover-color: $orange;
$navbar-padding-y: 0.2rem;
$navbar-light-color: #FFFFFF;
$navbar-light-active-color: #FFFFFF;
$navbar-light-hover-color: $orange;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1020px,
);


@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/css/all.css';

body {
  background-color: black;
}

#mainnav {
    background: linear-gradient(to bottom, #016719 0%, #016719 40%,  #004118 60%, $primary 100%);
}
.nav-link {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  color: $gray-300;
  text-shadow: 2px 2px 2px $gray-800;
}

.breadcrumb .separator {
  margin: 0.3rem;
  color: $primary;
}

@media (max-width: 767.98px) {
  .pagination .page-link:not([rel=next]):not([rel=prev]) {
    display: none;
  }
}

h1 {font-family: 'Unica One', sans-serif; color: $orange; font-size: 3rem; line-height: 1;}
h2 {font-family: 'Unica One', sans-serif; color: $primary; font-size: 1.5rem; font-style: italic;}
h1 + h2 {margin-top: -10px}
h3 {font-size: 1.4rem; margin-top: 1.5rem}

ul.freccina {list-style-type: none; margin-bottom: 16px; padding-left: 0}
ul.freccina li {background: url('../../public_html/img/li.gif') top left no-repeat; margin: 5px 0; padding: 0 0 0 20px;}

img.float_L {float:left;margin:3px 8px 3px 0;}
img.float_R {float:right;margin:3px 0 3px 8px;}

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
  --bs-tooltip-color: var(--bs-white);
}